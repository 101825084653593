.list {
  height: 92vh;
  margin-top: 4%;
  padding: 2% 4%;

  > button {
    float: right;
    width: auto;
    height: auto;
  }
}

.pages-list {
  margin-top: 4% !important;
}

.create-btn {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
}

.select-store {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.action-bar {
  width: 100% !important;
}

.search-btn {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px 15px !important;
  border-radius: 5px;
  cursor: pointer;
  height: auto !important;
  width: auto;
}

.create-btn:hover {
  background-color: #f7f7f7;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5%;
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px 12px;
    text-align: left;
  }
  th {
    background-color: #f5f5f5;
  }
  td {
    button {
      margin-right: 5px;
      background: none;
      border: none;
      cursor: pointer;
      color: #555;
    }
  }
}
