@import "../../../assets/scss/theme.scss";

#gjs {
  border: 3px solid #444;
}

.gjs-cv-canvas {
  top: 0px;
  height: calc(100% - 44px);
}

.gjs-block {
  width: auto;
  height: auto;
  min-height: auto;
}

.gjs-one-bg {
  background-color: #2a3042;
}

.gjs-four-color-h {
  width: 45% !important;
}

.gjs-cv-canvas {
  top: 44px;
}
.gjs-block-categories {
  flex-direction: column-reverse !important;
}
.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}
.panel__basic-actions {
  position: initial;
}

.store-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    margin: 0px 0px 15px 20px;
    background: #2a3042 !important;
    border: none !important;
  }
}
.select2-selection {
  z-index: 9 !important;
}
