@import "../../../assets/scss/theme.scss";

.offer-product-container {
  button {
    width: 100%;
    background: white;
    border-color: #ced4da;
    color: black;
  }
}
.quantity-checkbox {
  display: flex;
  align-items: center;
  input {
    margin: 0px 5px 1px 0px;
  }
}

.back-button {
  cursor: pointer;
  background: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 14px;
  border-radius: 6px;
}
