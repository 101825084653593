.bpmn {
  margin-top: 70px;
  height: calc(100vh - 70px);
  position: relative;
}

.bpmn-actions-group {
  background-color: white!important;
  position: absolute;
  top: 90%;
  display: flex;
  right: 45%;
  padding: 5px;
  z-index: 100;
  > button {
    width: auto;
    height: 38px;
    cursor: pointer;
  }
}

.bjs-powered-by {
  display: none;
}

.download-btn {
  background-color: #EFEFEF;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.download-btn:hover {
  background-color: #f7f7f7;
}

.funnel-details {
  padding: 10px;
  padding-bottom: 5px;
  background-color: white;
  border-bottom: 1px solid #ddd;
  h3,
  h4,
  p {
    margin: 5px 0;
  }
}

.djs-palette {
  background-color: white;
  border: none;
  width: 55px;
}

.djs-palette-entries .group .entry {
  width: 45px;
  background-color: #EFEFEF;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 8px;
}

.djs-context-pad {
  background-color: white;
  height: 40px;
  display: flex!important;
  flex-direction: row!important; /* Set row direction to arrange groups horizontally */
  align-items: center!important; /* Center groups vertically */
  justify-content: center!important; /* Optional: Centers groups horizontally */
  gap: 5px; /* Adjust space between the groups */
}

/* Optional: Ensure individual groups are also centered */
.djs-context-pad .group {
  margin-bottom: 7px;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex!important; /* Use flexbox to align children inside each group */
  flex-direction: row!important; /* Keep each group's items in a column */
  align-items: center!important;
  justify-content: center!important;
}

.djs-context-pad .group .entry {
  background-color: #EFEFEF;
  width: 35px;
  height: 35px;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  box-shadow: none!important;
  border-radius: 8px!important;
}

.djs-context-pad .group:not(:last-child)::after {
  content: "";
  display: block;
  width: 1px;
  height: 20px;
  background-color: #000;
  margin-left: 15px;
}

.djs-context-pad .group {
  position: relative;
}

.some {
  display: flex;
  flex-direction: row;
}

.bpmn {
  width: 80%;
}

.properties {
  width: 20%;
  margin-top: 70px;
  position: relative;
}

/* Remove the header as you did */
.bio-properties-panel-group-header {
  display: none!important;
}

/* Ensure the properties entries are stacked without extra space between them */
.bio-properties-panel-group-entries {
  display: block!important;
  overflow-y: auto!important;
}

/* Ensure the properties container stretches across the available height */
.bio-properties-panel-container {
  display: flex!important;
  flex-direction: column!important;
}

/* Ensure the properties panel fills the available height */
.bio-properties-panel {
  display: flex!important;
  flex-direction: column!important;
}

/* This allows the properties panel to scroll if necessary */
.bio-properties-panel-scroll-container {
  flex-grow: 1!important;
  overflow-y: auto!important;
  padding: 0!important;
}

/* Adjust the flex behavior for properties to remove extra spacing between entries */
.bio-properties-panel-group-entries {
  flex-grow: 1!important;
  display: flex!important;
  flex-direction: column!important;
  align-items: flex-start!important;
  justify-content: flex-start!important; /* Ensure items stack at the top */
  padding: 0!important; /* Remove padding that causes extra space */
}

/* Tighten the layout of individual property entries */
.bio-properties-panel-entry {
  margin-bottom: 5px!important; /* Smaller margin between entries */
  flex-grow: 0!important; /* Prevent individual entries from growing too much */
}

.bio-properties-panel {
  background-color: white!important; 
  height: calc(100vh - 140px)!important;
}

.bio-properties-panel-header {
  max-height: 55px!important;
}

#custom-message-container {
  background-color: white!important;
  height: calc(100vh - 70px)!important;
}

.no-selection-message {
  font-size: 16px;
  text-align: center;
  padding-top: 50px;
}