$height-offset: calc(70px + 2rem);

.funnels-list-container {
  padding: 0 1rem;
  height: calc(100vh - #{$height-offset});
  margin-top: $height-offset !important;
  overflow: auto;
}

.funnels-list {
  background-color: transparent!important;
  padding: 0 !important;
  width: 100%;
  > button {
    float: right;
    width: auto;
    height: auto;
  }
}

.funnels-table-header tr th {
  background-color: #E5E5E5!important;
  text-align: center;
}

.rounded-table {
  border-radius: 20px;
  border: 1px solid #d5d5d5;
}

.create-funnel-btn {
  background-color: #142541!important;
  color: white!important;
  border: none!important;
  padding: 10px 15px!important;
  border-radius: 8px!important;
  cursor: pointer!important;
  margin-top: 1rem!important;
}


.filter-funnel-btn {
  background-color: #2e579b!important;
  color: white!important;
  border: none!important;
  padding: 10px 15px!important;
  border-radius: 8px!important;
  cursor: pointer!important;
  margin-top: 1rem!important;
}

/* Add this to your list-funnels.scss file */
.input-wrapper, .select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-wrapper input {
  padding-left: 30px; /* Adjust padding to fit the search icon on the left */
}

.select-wrapper select {
  padding-right: 30px; /* Adjust padding to fit the caret icon on the right */
}

.input-wrapper .search-icon {
  position: absolute;
  left: 10px; /* Positions the search icon on the far left of the input */
  top: 50%;
  transform: translateY(-50%);
  color: #888; /* Optional: Adjust icon color */
}

.select-wrapper .angle-icon {
  position: absolute;
  right: 10px; /* Positions the caret icon on the far right of the select */
  top: 50%;
  transform: translateY(-50%);
  color: #888; /* Optional: Adjust icon color */
}

.pagination .page-item .active {
  background-color: white !important;
  border-color: #142541 !important;
}

.delete-funnel-theme {
  color: red
}

.custom-dropdown-menu {
  z-index: 1050; /* Ensure it's high enough to stay on top */
  background-color: white; /* White background for visibility */
  position: absolute; /* Make it appear above other content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for clarity */
  border-radius: 4px;
  margin-top: 0; /* Align directly under the ellipsis icon */
  overflow: visible; /* Prevent cropping */
}

.action-cell {
  width: 72px!important;
  padding-left: 34px!important;
}

.select-store {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.action-bar {
  width: 100% !important;
}

.search-btn {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px 15px !important;
  border-radius: 5px;
  cursor: pointer;
  height: auto !important;
  width: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5%;
  margin-top: 1rem;
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px 12px;
    text-align: left;
    background-color: white !important;
  }
  th {
    background-color: #f5f5f5;
  }
  td {
    button {
      margin-right: 5px;
      background: none;
      border: none;
      cursor: pointer;
      color: #555;
    }
  }
}

.pagination-listing-container {
  display: flex;
  justify-content: center;
  align-self: center;
}

.applied-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.filter-tag {
  background-color: #e0e0e0;
  border-radius: 12px;
  padding: 5px 10px;
  font-size: 0.9em;
  color: #333;
}
