$primary-color: #233951;
$white-color: white;

.forbidden {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;

  .account-pages {
    .card,
    .card-body,
    .d-flex,
    .d-grid {
      border-radius: 0;
      margin-bottom: 20px;
    }

    .card-body {
      background: $primary-color;
    }

    .d-flex span {
      color: $white-color;
    }

    .d-grid button {
      background: $white-color;
      color: $primary-color;
      border-color: $primary-color;
      font-size: 20px;
      font-weight: 800;
    }
  }

  .logo {
    background: $primary-color;

    .alignment {
      justify-content: center;
    }
  }

  .text-box {
    justify-content: center;
    margin-top: 20px;
    font-size: 17px;
    text-align: justify;
  }

  .help-desk-button {
    background: $white-color;
    color: $primary-color;
    border-color: $primary-color;
    font-size: 20px;
    font-weight: 800;
  }
}