.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
}

.flex-container.wrap {
  flex-wrap: wrap;
}

.flex-container .item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 1.5rem;
}

.flex-container .item > * {
  flex: 0 0 50%; /* Each item takes up 50% of the container width */
}

.flex-container .item > .dropbox {
  display: inline;
}

.md-1 {
  width: 300px !important;
}

.filters-container {
  margin-bottom: 1.5rem;
}

.filters-container .col {
  display: flex;
  gap: 15px;
}

.dropdown-container {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.dropdown-container .dropdown {
  margin: 0;
  cursor: pointer;
}

.dropdown-container .title {
  line-height: 2rem;
  margin-right: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
}
