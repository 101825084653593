@import "../../../assets/scss/theme.scss";

.upload-modal {
  .country-input {
    margin-bottom: 15px;
  }

  #uploader-box {
    width: 450px;
    border: 2px dashed $gray-400;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }

  .icon-wrapper {
    width: 100%;
    height: 64px;
    padding: 10px;
    border: 1px solid $gray-400;
    border-radius: 4px;
    margin-top: 15px;

    .icon {
      display: inline-block;
      vertical-align: text-bottom;
    }

    .file-name {
      display: inline-block;
      font-size: 14px;
      color: $gray-600;
      margin-left: 10px;
    }
  }
}
