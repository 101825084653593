@import "../../../assets/scss/theme.scss";

.delete-tax-btn-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
}

.coupon-form {
  margin-bottom: 20px;
}

.tax-form-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .tax-title {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.taxes-list-container {
  .multi-tax-delete {
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 3px;
    padding-left: 12px;
    img {
      cursor: pointer;
    }
  }
}
