@import "../../../assets/scss/theme.scss";

.product-table {
  td {
    background-color: white !important;
  }
  .status-active {
    background: #38bb8b;
    padding: 3px 12px;
    border-radius: 30px;
    color: white;
  }
  .status-inactive {
    background: #e83e8c;
    padding: 3px 12px;
    border-radius: 30px;
    color: white;
  }
  .product-actions {
    i {
      font-size: 18px;
      cursor: pointer;
      margin-right: 13px;
    }
    .duplicate {
      color: #f1b44c;
    }
    .edit {
      color: #38bb8b;
    }
    .delete {
      color: #e83e8c;
    }
  }
}

.product-filter-container {
  margin-bottom: 20px;
}

.product-create-btn {
  button {
    width: 100%;
  }
}

.delete-spinner {
  color: #e83e8c !important;
  width: 20px;
  height: 20px;
  margin: 0px;
}
.duplicate-spinner {
  color: #f1b44c !important;
  width: 20px !important;
  height: 20px !important;
  margin: 0px 13px 0px 0px !important;
}
