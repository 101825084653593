.subscription-shipping-address-modal {
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
}

.container-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px !important;
  margin-top: auto;
  margin-bottom: 40px;
  background-color: transparent !important;
  align-items: center;
}


.btn-accept {
  margin-top: 25px;
  margin-right: 10px;
}

.container-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px !important;
  margin-top: auto;
  margin-bottom: 40px;
  background-color: transparent !important;
  align-items: center;
}

.subscription-shipping-address-modal .modal-content {
  width: 100%;
  padding: 20px;
}

