.subscription-due-date-modal {
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
}

.subscription-due-date-modal .modal-content {
  width: 100%;
  padding: 20px;
}

.left-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dropdown-group {
  margin-bottom: 10px;
}

.dropdown {
  width: 120px;
  height: 40px;
  font-size: 14px;
  box-sizing: border-box;
}

.dropdown .btn-secondary {
  background-color: transparent;
  border-color: grey;
  color: black;
}

.container-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px !important;
  margin-top: auto;
  margin-bottom: 40px;
  background-color: transparent !important;
  align-items: center;
}