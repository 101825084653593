@import "../../assets/scss/theme.scss";

.tax-filter-container {
  margin-bottom: 20px;
}

.tax-table {
  th,
  td {
    background-color: white !important;
  }
}

.tax-table-container {
  .centered-cell {
    text-align: center;
    vertical-align: middle;
    position: relative;
  }

  .centered-content {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
