@import "../../../../../assets/scss/theme.scss";

.subscription-frequency-modal {
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
}

.subscription-frequency-modal .modal-content {
  width: 100%;
  padding: 20px;
}

.left-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}

.upper-left-group {
  margin-bottom: 10px;
}

.middle-and-lower-left-group {
  margin-bottom: 10px;
}
/*
.input, .dropdown {
  width: 120px;
  height: 40px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
*/
.dropdown .btn-secondary {
  background-color: transparent;
  border-color: grey;
  color: black;
  width: 100%;
}

.container-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px !important;
  margin-top: auto;
  margin-bottom: 40px;
  background-color: transparent !important;
  align-items: center;
}

.btn-accept {
  margin-top: 25px;
  margin-right: 10px;
  background: $green;
}
