//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}

table {
  margin-top: 0px !important;
}
