.table-container {
  width: 100%;
  padding: 15px 0px;
  margin: 30px 0px;
}

.cell-no-lines {
  border-top: none;
  border-left: none;
  border-right: none;
}

.text-center {
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.text-left {
  text-align: left;
  vertical-align: middle;
  position: relative;
}
