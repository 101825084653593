@import "../../../assets/scss/theme.scss";

.shop-selection-title {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 40px;
}

.shop-selection-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  .shop-button {
    margin-bottom: 10px;
    padding: 13px;
    font-size: 18px;
    background: white;
    color: #556ee6;
  }
  .selected-shop-button {
    margin-bottom: 10px;
    padding: 13px;
    font-size: 18px;
    background: #556ee6;
    color: white;
  }
}
