@import "../../../assets/scss/theme.scss";

.form-btn {
  justify-content: flex-end;
}
.countries-checkbox {
  margin-right: 15px;
}

.payment-divider {
  width: 100%;
  height: 1px;
  background-color: lightgray;
  margin: 15px 0px;
}
.priority-info {
  i {
    font-size: 20px;
    margin-left: 10px;
    color: #556ee6;
    cursor: pointer;
  }
}

.display-criteria-container {
  .display-criteria-label {
    display: flex;
    align-items: center;
    select {
      width: 160px;
      margin: 0px 12px;
    }
  }
  .display-criteria-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .display-criteria-box {
      display: flex;
      align-items: center;
      .display-checkbox {
        margin: -2px 10px 0px 0px;
      }
    }
  }
  .display-criteria-right-section {
    display: flex;
    align-items: center;
    input {
      margin-left: 10px;
    }
    .input-grp {
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
    select {
      min-width: 160px;
    }
  }
}

.priority-btn-section {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.offer-section-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upsells-product-box {
  border-bottom: 1px solid #e1e3e5;
  padding-bottom: 10px;
  margin-bottom: 10px;
  .upsells-product-container {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
      border-radius: 5px;
      border: 1px solid #8c919645;
      padding: 0px 8px;
      margin-right: 20px;
    }
    .status-active {
      background: #38bb8b;
      padding: 3px 12px;
      border-radius: 30px;
      color: white;
    }
    .status-inactive {
      background: #e83e8c;
      padding: 3px 12px;
      border-radius: 30px;
      color: white;
    }
    .offer-details {
      width: 100%;
      display: flex;
      .offer-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .offer-title {
          margin-bottom: 2px;
        }
      }
      .offer-action {
        width: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        button {
          margin-left: 10px;
          display: none;
        }
      }
    }
  }
}

.upsells-product-container:hover .offer-action button {
  display: inline-block !important;
}

.parent-product-container:last-child {
  border: none !important;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.downsell-product-container {
  box-shadow: 0px 0px 5px rgba(23, 24, 24, 0.05),
    0px 1px 2px rgba(23, 24, 24, 0.05);
  padding: 24px;
  border-radius: 11px;
  margin-left: 90px;
  background-color: #fafbfb;
  margin-top: 10px;
}

.offer-set-main-container {
  border-top: 1px solid #e1e3e5;
  padding: 6px 0px 0px;
}
