.subscription-product-modal {
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
}

.subscription-product-modal .modal-content {
  width: 130%;
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.container-body {
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 50vh;
  padding: 10px;
}

.left-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.components {
  margin-bottom: 10px;
}

.dropdown {
}

.box {
  justify-content: center;
  align-items: center;
}

.dropdown .btn-secondary {
  background-color: transparent;
  border-color: grey;
  color: black;
}

.container-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px !important;
  margin-top: auto;
  margin-bottom: 40px;
  background-color: transparent !important;
  align-items: center;
}

.btn-cancel {
  background: red !important;
  margin-top: 25px;
  margin-left: 10px;
}

.btn-accept {
  margin-top: 25px;
  margin-right: 10px;
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}
