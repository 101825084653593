.subscription-modify-modal {
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
}

.subscription-modify-modal .modal-content {
  width: 100%;
  padding: 20px;
}

.subscription-modify-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
}

.subscription-modify-container button {
  flex-shrink: 0;
  width: 100px;
  height: 40px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  margin: 10px;
}

.subscription-modify-container .description {
  margin-left: 30px;
  text-align: justify;
}

.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-container.wrap {
  flex-wrap: wrap;
}

.flex-container .item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 1.5rem;
}

.flex-container .item > * {
  flex: 0 0 50%; /* Each item takes up 50% of the container width */
}

.flex-container .item > .dropbox {
  display: inline;
}

.btn-cancel {
  margin-top: 25px;
  margin-left: 10px;
}

.subscription-actions {
  justify-content: space-evenly;
  display: flex;
  flex: 1 0 0;
}

.subscription-actions > box-icon {
  cursor: pointer;
}

.subscription-actions > box-icon:disabled {
  opacity: 0.5;
  pointer-events: none;
}