.search-product-list {
  max-height: 508px;
  overflow: auto;
  .search-product-item {
    display: flex;
    align-items: center;
    padding: 15px 10px 15px 0px;
    border-top: 1px solid #dde0e4;
    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
      border-radius: 5px;
      border: 1px solid #8c919645;
      padding: 0px 8px;
      margin: 0px 20px 0px 14px;
    }
    .search-checkbox {
      width: 30px;
      height: auto;
    }
  }
}
.selected-product-item {
  display: flex;
  align-items: center;
  padding: 15px 10px 15px 0px;
  border-top: 1px solid #dde0e4;
  img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    border-radius: 5px;
    border: 1px solid #8c919645;
    padding: 0px 8px;
    margin: 0px 20px 0px 14px;
  }
}

.selected-product-item-list {
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 5px;
    border: 1px solid #8c919645;
    padding: 0px 8px;
    margin: 0px 20px 0px 14px;
  }
}
.dnd-product-selector {
  .search-product-list {
    height: 40vh;
    max-height: 508px;

    .search-product-item {
      border-top: 1px solid #b9a5a5;
    }
  }

  .selected-product-item {
    border-top: 1px solid #b9a5a5;
  }

  .pagination {
    gap: 2px;
  }

  .page-link {
    background-color: var(--gjs-main-light-color);
    color: #b9a5a5;
    border-radius: 2px;
    border: none;
    &:hover {
      background-color: var(--gjs-main-light-color);
    }
  }

  .page-item.active .page-link {
    background-color: #3f3336;
  }
}
