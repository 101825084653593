.status-container {
  max-width: "80px";
  white-space: "nowrap";
  overflow: "hidden";
  text-overflow: "ellipsis";
}

.date-column {
  width: max-content;
}

.subscription-id-container {
  width: 60px;
}
